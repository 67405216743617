import React, { PureComponent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { toast } from "react-hot-toast";
import renderHTML from "react-render-html";
import {MutatingDots} from "react-loader-spinner";
import { accentColor, secondaryColor } from "../../styles/Colors";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import { SFAlert } from "../../components/Alert";
import { isMobile, isAndroid } from "react-device-detect";
import Button from "react-bootstrap/Button";
import { Helmet } from "react-helmet";
import { ApplicationClientEndpoint } from "../../utils/Constants";
import { CommunityService } from "../../services/CommunityService";
import { CommunityPost } from "../../models/CommunityPost";
import { Workout } from "../../models/Workout";
import { Recipe } from "../../models/Recipe";
import { RecipeService } from "../../services/RecipeService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { getWorkoutImage } from "../../styles/Images";
import { Route } from "../../utils/Routes";

interface IOwnState {
    post?: CommunityPost;
    workout?: Workout;
    recipe?: Recipe;
}

export class ViewCommunityPostScreenComponent extends PureComponent<RouteComponentProps, IOwnState> {
    private readonly communityService = new CommunityService();
    private readonly recipeService = new RecipeService();
    public constructor(props: RouteComponentProps) {
        super(props);
        this.state = {
            post: undefined,
            workout: undefined,
            recipe: undefined,
        };
    }

    public componentDidMount() {
        const routerState = this.props.location.state as { item?: CommunityPost } | undefined;
        const item = routerState?.item;
        const { id } = this.props.match.params as { id: string };
        if (item && item._id === id) {
            this.fetchRecipe(item);
            this.fetchWorkout(item);
            this.setState({
                post: item,
            });
            return;
        }
        this.communityService
            .fetchPost(id)
            .then((newsItem) => {
                if (!newsItem) {
                    toast.error("Failed to load post, please try again later");
                    return;
                }
                this.fetchRecipe(newsItem);
                this.fetchWorkout(newsItem);
                this.setState({ post: newsItem });
            })
            .catch(() => {
                toast.error("Failed to load post, please try again later");
            });
        this.communityService
            .incrementViewCount(id)
            .then(() => {
                // no op
            })
            .catch(() => {
                // no op
            });
    }

    public render() {
        return (
            <>
                {this.state.post ? (
                    <Container
                        style={{
                            marginTop: 16,
                            marginBottom: 16,
                        }}>
                        <Helmet>
                            <meta
                                name="apple-itunes-app"
                                content={`app-id=1564346325, app-argument=post-${this.state.post._id}`}
                            />
                            <meta property="og:title" content={`Strong Foundation - ${this.state.post.title}`} />
                            <meta
                                property="og:url"
                                content={`${ApplicationClientEndpoint.substring(
                                    0,
                                    ApplicationClientEndpoint.length - 1,
                                )}${this.props.match.url.replace("community", "post")}`}
                            />
                            <meta property="og:image" content={this.state.post.headerImage} />
                            <meta property="og:description" content={this.state.post.content} />
                        </Helmet>
                        {this.state.post.headerImage ? (
                            <div
                                className="module"
                                style={{
                                    backgroundImage: `url(${this.state.post.headerImage ?? "/sf-feature.png"})`,
                                }}>
                                <Image
                                    className="module-inside"
                                    src={this.state.post.headerImage}
                                    style={{
                                        objectFit: "contain",
                                        width: "100%",
                                        maxHeight: 300,
                                    }}
                                    fluid
                                />
                            </div>
                        ) : null}
                        <Row>
                            <Col>
                                <h1>{this.state.post.title}</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col>{renderHTML(this.state.post.content)}</Col>
                        </Row>
                        {this.state.recipe ? (
                            <Card>
                                <Row>
                                    <Col md={3}>
                                        <Card.Img
                                            variant="top"
                                            src={this.state.recipe?.image ?? "/sf-feature.png"}
                                            style={{
                                                maxHeight: 100,
                                                objectFit: "cover",
                                            }}
                                        />
                                    </Col>
                                    <Col
                                        md={6}
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            textAlign: "center",
                                        }}>
                                        <h5>{this.state.recipe?.title}</h5>
                                    </Col>
                                    <Col
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            textAlign: "center",
                                        }}>
                                        <FontAwesomeIcon
                                            id="icon"
                                            icon={faChevronRight}
                                            size={"2x"}
                                            onClick={() => {
                                                this.props.history.push(`${Route.Recipes}/${this.state.recipe?.id}`, {
                                                    item: this.state.recipe,
                                                });
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        ) : null}
                        {this.state.workout ? (
                            <Card>
                                <Row>
                                    <Col md={3}>
                                        <Card.Img
                                            variant="top"
                                            src={getWorkoutImage().image ?? "/sf-feature.png"}
                                            style={{
                                                maxHeight: 100,
                                                objectFit: "cover",
                                            }}
                                        />
                                    </Col>
                                    <Col
                                        md={6}
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            textAlign: "center",
                                        }}>
                                        <h5>{this.state.workout?.name}</h5>
                                    </Col>
                                    <Col
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            textAlign: "center",
                                        }}>
                                        <FontAwesomeIcon
                                            id="icon"
                                            icon={faChevronRight}
                                            size={"2x"}
                                            onClick={() => {
                                                this.props.history.push(
                                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                    `${Route.WorkoutBase}${this.state.post?.workoutId}`,
                                                );
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        ) : null}
                    </Container>
                ) : (
                    <Container
                        style={{
                            textAlign: "center",
                            margin: 16,
                        }}>
                        <MutatingDots
                            
                            color={secondaryColor}
                            height={200}
                            width={100}
                            secondaryColor={accentColor}
                        />
                        <h3>Loading...</h3>
                    </Container>
                )}
                <div style={{ position: "fixed", bottom: 0, left: "5%", right: "5%", zIndex: 1000 }}>
                    <SFAlert
                        show={isMobile}
                        variant={"info"}
                        heading={"Open post in Strong Foundation mobile app"}
                        body={
                            <Button
                                onClick={this.redirect}
                                variant={"success"}
                                style={{ width: "100%", marginLeft: "auto", marginRight: "auto" }}>
                                Open
                            </Button>
                        }
                    />
                </div>
            </>
        );
    }
    private fetchRecipe = (item: CommunityPost) => {
        if (item.recipeId > Number.MIN_SAFE_INTEGER) {
            this.recipeService
                .fetchRecipe(item.recipeId.toString())
                .then((data) => {
                    if (!data) {
                        return;
                    }
                    const { recipe } = data;
                    this.setState({ recipe });
                })
                .catch(() => {
                    //do nothing
                });
        }
    };
    private fetchWorkout = (item: CommunityPost) => {
        if (item.workoutId && item.workoutId.length > 0) {
            this.communityService
                .getWorkout(item.workoutId)
                .then((data) => {
                    if (!data) {
                        return;
                    }
                    const { workout } = data;
                    this.setState({ workout });
                })
                .catch(() => {
                    //do nothing
                });
        }
    };
    private redirect = async () => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const windowNav = navigator as any;
        if (windowNav.getInstalledRelatedApps) {
            const installedApps: { id: string }[] = await windowNav.getInstalledRelatedApps();
            const nativeApp = installedApps.find((app) => app.id === "com.coding.casa.strongfoundation");
            if (!nativeApp && isAndroid) {
                window.open("https://play.google.com/store/apps/details?id=com.coding.casa.strongfoundation", "_blank");
                return;
            }
        }
        const { id } = this.props.match.params as { id: string };
        window.location.assign(`strongfoundation://shared/post?id=${id}`);
    };
}

export const ViewCommunityPostScreen = withRouter(ViewCommunityPostScreenComponent as any);
