import { HomeScreen } from "../screens/home/HomeScreen";
import { ViewCommunityPostScreen } from "../screens/community/ViewCommunityPostScreen";
import { CommunityScreen } from "../screens/community/CommunityScreen";
import { RecipeScreen } from "../screens/recipe/RecipeScreen";
import { WorkoutBuilderScreen } from "../screens/wobuilder/WorkoutBuilder";
import { WorkoutScreen } from "../screens/workout/WorkoutScreen";
import { ViewRoutineScreen } from "../screens/routine/ViewRoutineScreen";

export enum Route {
    Home = "/",
    Community = "/community",
    Recipes = "/recipes",
    Recipe = "/recipes/:id",
    WorkoutBuilder = "/workoutbuilder",
    ViewCommunityPost = "/community/:id",
    ViewRoutine = "/routine/:id",
    Workout = "/workout/:id",
    WorkoutBase = "/workout/",
}

export const Routes = [
    {
        route: Route.Home,
        component: HomeScreen,
        exact: true,
    },
    {
        route: Route.Community,
        component: CommunityScreen,
        exact: true,
    },
    {
        route: Route.ViewCommunityPost,
        component: ViewCommunityPostScreen,
        exact: true,
    },
    {
        route: Route.ViewRoutine,
        component: ViewRoutineScreen,
        exact: true,
    },
    {
        route: Route.Recipe,
        component: RecipeScreen,
        exact: true,
    },
    {
        route: Route.WorkoutBuilder,
        component: WorkoutBuilderScreen,
        exact: true,
    },
    {
        route: Route.Workout,
        component: WorkoutScreen,
        exact: true,
    },
];
