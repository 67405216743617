import React, { PureComponent, useState } from "react";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faDumbbell, faClock } from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Exercise } from "../../models/Exercise";
import { SetsAndReps } from "../../models/SetsAndReps";
import { secondaryColor } from "../../styles/Colors";
import {SfStringBuilder} from "../../utils/StringBuilder";

interface IProps {
    exercise: Exercise;
    onRemoved: (exercies: Exercise) => void;
}
interface IOwnState {
    setsAndReps: SetsAndReps[];
}

export default class WorkoutExercise extends PureComponent<IProps, IOwnState> {
    private setsAndRepsCbs: (() => SetsAndReps)[] = [];

    public constructor(props: IProps) {
        super(props);
        this.state = {
            setsAndReps: [],
        };
    }

    public render() {
        return (
            <ListGroupItem>
                <Row>
                    <Col md={12}>
                        <h5>{this.props.exercise.fields.name}</h5>
                        <FontAwesomeIcon
                            style={{ color: "red", float: "right" }}
                            icon={faTrash}
                            onClick={() => {
                                this.props.onRemoved(this.props.exercise);
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <h6>Sets and Reps</h6>
                        <hr />
                        <Button variant={"info"} onClick={this.addSet}>
                            Add Set
                        </Button>
                    </Col>
                    {this.state.setsAndReps.map((item, index) => {
                        return (
                            <Row style={{ marginTop: 4, marginLeft: "auto", marginRight: "auto" }} key={index}>
                                <Col xs={12} sm={12}>
                                    <SetsAndRepsComponent
                                        sr={item}
                                        index={index}
                                        onRemoveSet={this.removeSet}
                                        getSetAndReps={(cb) => {
                                            this.setsAndRepsCbs.push(cb);
                                        }}
                                    />
                                </Col>
                            </Row>
                        );
                    })}
                </Row>
            </ListGroupItem>
        );
    }
    private removeSet = (index: number) => {
        const currentSetsAndReps = this.state.setsAndReps;
        currentSetsAndReps.splice(index, 1);
        const setsAndReps = ([] as SetsAndReps[]).concat(currentSetsAndReps);
        this.setsAndRepsCbs = [];
        this.setState({ setsAndReps });
    };
    private addSet = () => {
        const currentSetsAndReps = this.state.setsAndReps;
        const setsAndReps = currentSetsAndReps.concat([new SetsAndReps()]);
        this.setsAndRepsCbs = [];
        this.setState({ setsAndReps });
    };

    public getSetsAndReps = (): string => {
        const cbs = this.setsAndRepsCbs;
        if (!cbs || cbs.length === 0) {
            return "";
        }
        const setsAndReps = cbs.map((cb) => cb());
        const builder = new SfStringBuilder();
        let hasAppended = false;
        setsAndReps.forEach((sr) => {
            if (hasAppended) {
                builder.append("-");
            }
            if (sr.sets === 0 && sr.reps === 0 && !sr.time) {
                hasAppended = false;
                return;
            }
            builder.append(`${sr.sets}_${sr.reps}_${sr.time ?? ""}`);
            hasAppended = true;
        });
        return builder.toString();
    };
}

const SetsAndRepsComponent = (props: {
    sr: SetsAndReps;
    index: number;
    onRemoveSet?: (index: number) => void;
    getSetAndReps?: (cb: () => SetsAndReps) => void;
}) => {
    const [isTimeBased, setIsTimeBased] = useState(props.sr.time && props.sr.time.length > 0);
    const timeRef = React.createRef<HTMLInputElement>();
    const repsRef = React.createRef<HTMLInputElement>();
    const setsRef = React.createRef<HTMLInputElement>();
    const getSetsAndRep = () => {
        const sets = setsRef.current?.valueAsNumber;
        const reps = repsRef.current?.valueAsNumber;
        return new SetsAndReps(
            !sets || isNaN(sets) ? 0 : sets,
            !reps || isNaN(reps) ? 0 : reps,
            isTimeBased ? timeRef.current?.value : "",
        );
    };
    if (props.getSetAndReps) {
        props.getSetAndReps(getSetsAndRep);
    }
    return (
        <Row>
            <Col xs={4}>
                <Form.Group>
                    <Form.Label>Sets</Form.Label>
                    <Form.Control type="number" placeholder="1" min="0" ref={setsRef} />
                </Form.Group>
            </Col>
            <Col xs={1}>
                <p>X</p>
            </Col>
            <Col xs={5}>
                {!isTimeBased ? (
                    <Form.Group>
                        <Form.Label>Reps</Form.Label>
                        <Form.Control type="number" placeholder="0" min="0" ref={repsRef} />
                    </Form.Group>
                ) : (
                    <Form.Group>
                        <Form.Label>Time</Form.Label>
                        <Form.Control type="text" placeholder="1:00" ref={timeRef} />
                    </Form.Group>
                )}
            </Col>
            <Col xs={1}>
                {!isTimeBased ? (
                    <FontAwesomeIcon
                        icon={faClock}
                        style={{ color: secondaryColor }}
                        onClick={() => setIsTimeBased(true)}
                    />
                ) : (
                    <FontAwesomeIcon
                        icon={faDumbbell}
                        style={{ color: secondaryColor }}
                        onClick={() => setIsTimeBased(false)}
                    />
                )}
            </Col>
            <Col xs={1}>
                <FontAwesomeIcon
                    icon={faTrash}
                    style={{ color: "red" }}
                    onClick={() => {
                        if (props.onRemoveSet) {
                            props.onRemoveSet(props.index);
                        }
                    }}
                />
            </Col>
        </Row>
    );
};
