import React, { useState } from "react";
import Alert from "react-bootstrap/Alert";

interface IProps {
    heading?: string;
    body: string | JSX.Element;
    show: boolean;
    variant?: string;
}

export const SFAlert = (props: IProps) => {
    const [show, setShow] = useState(props.show);
    if (show) {
        return (
            <Alert variant={props.variant} onClose={() => setShow(false)} dismissible>
                {props.heading ? <Alert.Heading style={{ textAlign: "center" }}>{props.heading}</Alert.Heading> : null}
                {props.body}
            </Alert>
        );
    }
    return null;
};
