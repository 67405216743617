/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { PureComponent } from "react";
import Form from "react-bootstrap/Form";
import { Workout } from "../../models/Workout";
import { SpotifyControl } from "./SpotifyControl";

interface IProps {
    item?: Workout;
    isPublished?: boolean;
    isReadOnly?: boolean;
    getFormSubmissionData: (cb: () => {
        description: string;
        name: string;
        spotifyPlaylistUrl: string;
        isYogaSequence: boolean;
        isTabataFlow: boolean;
        yogaTTSPace: number;
        phoneNumbers?: string;
        isPublished: boolean;
    }) => void;
}

export default class WorkoutForm extends PureComponent<IProps> {
    private name = React.createRef<HTMLInputElement>();
    private description = React.createRef<HTMLInputElement>();
    private yogaTTSPace = React.createRef<HTMLInputElement>();
    private isYoga = React.createRef<HTMLInputElement>();
    private isTabata = React.createRef<HTMLInputElement>();
    private spotifyPlaylistUrl: () => string = () => "";
    private phoneNumbers = React.createRef<HTMLInputElement>();

    public componentDidMount() {
        this.props.getFormSubmissionData(this.formSubmitted);
    }

    public render() {
        return (
            <React.Fragment>
                <Form.Group className="wo_form_group">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Title"
                        ref={this.name}
                        disabled={this.props.isReadOnly}
                        defaultValue={this.props.item?.name}
                    />
                </Form.Group>
                <Form.Group className="wo_form_group">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Description"
                        ref={this.description}
                        disabled={this.props.isReadOnly}
                        defaultValue={this.props.item?.description}
                    />
                </Form.Group>
                <Form.Group className="wo_form_group">
                    <Form.Label>Spotify Playlist</Form.Label>
                    <SpotifyControl getPlaylist={(getPlaylist) => (this.spotifyPlaylistUrl = getPlaylist)} />
                </Form.Group>
                <Form.Group className="wo_form_group">
                    <Form.Label>Is this a yoga or tabata styled workout?</Form.Label>
                    <Form.Check
                        type="checkbox"
                        defaultChecked={this.props.item?.isYogaSequence}
                        label="Yoga"
                        ref={this.isYoga}
                    />
                    <Form.Check
                        type="checkbox"
                        defaultChecked={this.props.item?.isTabataFlow}
                        label="Tabata"
                        ref={this.isTabata}
                    />
                </Form.Group>
                <Form.Group className="wo_form_group">
                    <Form.Label>What is the pace between poses (in seconds)?</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="30"
                        ref={this.yogaTTSPace}
                        disabled={this.props.isReadOnly}
                        defaultValue={this.props.item?.yogaTTSPace}
                        min={30}
                        step={10}
                        max={120}
                    />
                </Form.Group>
                <Form.Group className="wo_form_group">
                    <Form.Label>Send the workout to your phone (optional)</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="+11234567890"
                        ref={this.phoneNumbers}
                        disabled={this.props.isReadOnly}
                    />
                    <Form.Text>
                        ex. +11234567890 or multiple numbers separated by commas; +11234567890,+05555555555
                    </Form.Text>
                </Form.Group>
            </React.Fragment>
        );
    }

    private formSubmitted = () => {
        const data = {
            name: this.name.current?.value ?? "",
            spotifyPlaylistUrl: this.spotifyPlaylistUrl(),
            description: this.description.current?.value ?? "",
            yogaTTSPace: this.yogaTTSPace.current?.value ? this.yogaTTSPace.current?.valueAsNumber : 90,
            isTabataFlow: this.isTabata.current?.checked === true,
            isYogaSequence: this.isYoga.current?.checked === true,
            phoneNumbers: this.phoneNumbers.current?.value,
            isPublished: false,
        };
        data.yogaTTSPace *= 1000;
        return data;
    };
}
