import React, { PureComponent } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { toast } from "react-hot-toast";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import renderHTML from "react-render-html";
import { Route } from "../../utils/Routes";
import { Helmet } from "react-helmet";
import { ApplicationClientEndpoint } from "../../utils/Constants";
import { CommunityPost } from "../../models/CommunityPost";
import { CommunityService } from "../../services/CommunityService";
import { uniqBy } from "lodash";

interface IOwnState {
    posts: CommunityPost[];
    lastBatch?: CommunityPost[];
    canLoadMore: boolean;
}

export class CommunityScreenComponent extends PureComponent<RouteComponentProps, IOwnState> {
    private readonly communityService = new CommunityService();
    public constructor(props: RouteComponentProps) {
        super(props);
        this.state = {
            posts: [],
            lastBatch: undefined,
            canLoadMore: true,
        };
    }

    public componentDidMount() {
        this.communityService
            .fetchPosts(Date.now(), 0, true)
            .then((items) => {
                this.setState({
                    posts: uniqBy(items, "_id"),
                    lastBatch: items,
                    canLoadMore: items.length % 20 === 0,
                });
            })
            .catch(() => {
                this.setState({ canLoadMore: false });
                toast.error("Failed to find community posts, please try again later");
            });
    }

    public render() {
        return (
            <>
                <h1>Strong Foundation - Community</h1>
                <Helmet>
                    <meta name="apple-itunes-app" content={`app-id=1564346325, app-argument=post`} />
                    <meta property="og:title" content="Strong Foundation - Community" />
                    <meta
                        property="og:url"
                        content={`${ApplicationClientEndpoint.substring(
                            0,
                            ApplicationClientEndpoint.length - 1,
                        )}${this.props.match.url.replace("community", "post")}`}
                    />
                    <meta property="og:description" content="What's going in the world of Strong Foundation" />
                    <meta property="og:site_name" content="Strong Foundation" />
                    <title>Strong Foundation - Community</title>
                </Helmet>
                <InfiniteScroll
                    dataLength={this.state.posts.length} //This is important field to render the next data
                    next={this.fetchData}
                    hasMore={this.state.canLoadMore}
                    loader={<h4>Loading...</h4>}
                    hasChildren
                    style={{ marginLeft: "5%", marginRight: "auto" }}>
                    {this.state.posts.map(this.newsCard)}
                </InfiniteScroll>
            </>
        );
    }

    private fetchData = async () => {
        try {
            const existingPosts = this.state.posts;
            const items = await this.communityService.fetchPosts(Date.now(), existingPosts.length, true);
            this.setState({
                posts: uniqBy(existingPosts.concat(items), "_id"),
                lastBatch: items,
                canLoadMore: items.length % 20 === 0,
            });
        } catch (e) {
            this.setState({
                canLoadMore: false,
            });
            toast.error("Failed to find news items, please try again later");
        }
    };

    private newsCard = (item: CommunityPost) => {
        return (
            <Card className="postCard" key={item._id}>
                <div
                    className="module"
                    style={{
                        backgroundImage: `url(${item.headerImage ?? "/sf-feature.png"})`,
                        borderTopLeftRadius: 15,
                        borderTopRightRadius: 15,
                    }}>
                    <Card.Img
                        variant="top"
                        className="module-inside"
                        src={item.headerImage ?? "/sf-feature.png"}
                        style={{
                            objectFit: "contain",
                            width: "100%",
                            maxHeight: 300,
                            borderTopLeftRadius: 15,
                            borderTopRightRadius: 15,
                        }}
                    />
                </div>
                <Card.Body>
                    <Card.Title>{item.title ?? "Strong Foundation"}</Card.Title>
                    <Card.Text style={{ maxHeight: 200, overflowY: "auto" }} className="postContent">
                        {renderHTML(item.content)}
                    </Card.Text>
                </Card.Body>
                <Button
                    variant={"info"}
                    style={{ maxWidth: "33%", marginLeft: 8, marginBottom: 8 }}
                    onClick={() => {
                        this.props.history.push(`${Route.Community}/${item._id}`, { item });
                    }}>
                    Read more
                </Button>
            </Card>
        );
    };
}

export const CommunityScreen = withRouter(CommunityScreenComponent as any);
