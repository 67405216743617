import axios from "axios";
import { ApplicationApiEndpoint, AzureClientId, isDev } from "./Constants";
import { sleep } from "./Utilities";
import atob from "atob"

export class AuthenticationHelper {
    private token: string | undefined = undefined;
    private isRetrievingToken = false;

    public constructor() {
        this.token = undefined;
    }

    public isAuthenticated = (): boolean => {
        if (!this.token) {
            return false;
        }
        const parts = this.token.split(".");
        if (parts.length < 3 || !parts[1]) {
            return false;
        }
        const decoded = atob(parts[1]);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const body = JSON.parse(decoded) as { [key: string]: any };
        const exp: number = body.exp;
        return Date.now() < exp;
    };

    public getToken = () => this.token ?? "";

    public fetchTokenAsync = async (): Promise<string> => {
        if (!this.token) {
            this.token = undefined;
        }
        if (this.token && this.isAuthenticated()) {
            return this.token;
        }
        if (this.isRetrievingToken) {
            while (this.isRetrievingToken) {
                await sleep(500);
            }
            return this.fetchTokenAsync();
        }
        this.isRetrievingToken = true;
        try {
            const access_token = (
                await axios.get(`${ApplicationApiEndpoint}authentication/token`, {
                    params: {
                        client_id: AzureClientId,
                        grant_type: "client_credentials",
                        scope: `https://strongfoundation${isDev ? "-dev" : ""}.dev/.default`,
                    },
                })
            ).data;
            this.token = access_token;
            return access_token;
        } catch (ex) {
            return "";
        } finally {
            this.isRetrievingToken = false;
        }
    };
}
