import { SpotifyPagingObject, SpotifyPlaylist } from "../models/SpotifyPagingObject";
import axios from "axios";

export class SpotifyService {
    private readonly SpotifyApiEndpoint = "https://api.spotify.com/v1/";

    public constructor(private readonly accessToken: string) {}

    public fetchUserPlaylists = async (): Promise<SpotifyPlaylist[]> => {
        try {
            const response = await axios.get<SpotifyPagingObject>(`${this.SpotifyApiEndpoint}me/playlists`, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`,
                },
                params: {
                    limit: 50,
                    offset: 0,
                },
            });
            return response.data.items;
        } catch (ex) {
            if (ex.response) {
                if (ex.response.status && ex.response.status === 401) {
                    throw ex;
                }
            }
            return [];
        }
    };

    public updatePlaylist = async (
        playlistId: string,
        body: { name?: string; public?: boolean; collaborative?: boolean; description?: string },
    ): Promise<{ [key: string]: string }> => {
        try {
            const response = await axios.post<{ [key: string]: string }>(
                `${this.SpotifyApiEndpoint}/playlists/${playlistId}`,
                body,
                {
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`,
                    },
                },
            );
            return response.data;
        } catch (ex) {
            if (ex.response) {
                if (ex.response.status && ex.response.status === 401) {
                    throw ex;
                }
            }
            return {};
        }
    };
}
