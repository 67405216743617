import React, { PureComponent } from "react";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Exercise } from "../../models/Exercise";

interface IProps {
    exercise: Exercise;
    isSelected?: boolean;
    onSelected: (exercies: Exercise) => void;
}

interface IState {
    isSelected?: boolean;
}

export default class SelectableExercise extends PureComponent<IProps, IState> {
    public constructor(props: IProps) {
        super(props);
        this.state = {
            isSelected: props.isSelected,
        };
    }

    public render() {
        return (
            <ListGroupItem
                onClick={() => {
                    this.setState({ isSelected: !this.state.isSelected }, () => {
                        this.props.onSelected(this.props.exercise);
                    });
                }}>
                {this.props.exercise.fields.name}
                <FontAwesomeIcon
                    style={{ color: this.state.isSelected ? "green" : "#000", float: "right" }}
                    icon={faCheckCircle}
                    onClick={() => {
                        this.setState({ isSelected: !this.state.isSelected }, () => {
                            this.props.onSelected(this.props.exercise);
                        });
                    }}
                />
            </ListGroupItem>
        );
    }
}
