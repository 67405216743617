import React, { PureComponent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { toast } from "react-hot-toast";
import renderHTML from "react-render-html";
import {MutatingDots} from "react-loader-spinner";
import { accentColor, secondaryColor } from "../../styles/Colors";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import { SFAlert } from "../../components/Alert";
import { isMobile, isAndroid } from "react-device-detect";
import Button from "react-bootstrap/Button";
import { CommunityService } from "../../services/CommunityService";
import { Workout } from "../../models/Workout";
import { Exercise } from "../../models/Exercise";
import { getWorkoutImage } from "../../styles/Images";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Helmet } from "react-helmet";
import { ApplicationClientEndpoint } from "../../utils/Constants";

interface IOwnState {
    workoutData?: { workout: Workout; exercises: Exercise[] };
}

export class WorkoutScreenComponent extends PureComponent<RouteComponentProps, IOwnState> {
    private readonly communityService = new CommunityService();
    public constructor(props: RouteComponentProps) {
        super(props);
        this.state = {
            workoutData: undefined,
        };
    }

    public componentDidMount() {
        const { id } = this.props.match.params as { id: string };
        this.communityService
            .getWorkout(id)
            .then((workoutData) => {
                if (!workoutData) {
                    toast.error("Failed to load workout, please try again later");
                    return;
                }
                this.setState({ workoutData: {
                    exercises: workoutData.exercises,
                    workout: Workout.copyWorkout(workoutData.workout)
                } });
            })
            .catch(() => {
                toast.error("Failed to load workout, please try again later");
            });
    }

    public render() {
        let workout = this.state.workoutData?.workout;
        const exercises = this.state.workoutData?.exercises;
        const image = getWorkoutImage();
        if (workout) {
            workout = Workout.copyWorkout(workout);
        }
        const { id } = this.props.match.params as { id: string };
        return (
            <>
                {workout && exercises ? (
                    <Container
                        style={{
                            marginTop: 16,
                            marginBottom: 16,
                        }}>
                        <Helmet>
                            <meta name="apple-itunes-app" content={`app-id=1564346325, app-argument=workout-${id}`} />
                            <meta property="og:title" content={`Strong Foundation - ${workout.name}`} />
                            <meta
                                property="og:url"
                                content={`${ApplicationClientEndpoint.substring(
                                    0,
                                    ApplicationClientEndpoint.length - 1,
                                )}${this.props.match.url}`}
                            />
                            <meta property="og:image" content={image.image} />
                            <meta property="og:description" content={workout.description} />
                            <meta property="og:site_name" content="Strong Foundation" />
                            <title>Strong Foundation - {workout.name}</title>
                        </Helmet>
                        <div
                            className="module"
                            style={{
                                backgroundImage: `url(${image.image})`,
                            }}>
                            <Image
                                className="module-inside"
                                src={image.image}
                                style={{
                                    objectFit: "contain",
                                    width: "100%",
                                    maxHeight: 300,
                                }}
                                fluid
                            />
                            {workout.spotifyPlaylistUrl ? (
                                <Image
                                    onClick={() => {
                                        window.open(workout?.getSpotifyHref(), "_blank");
                                    }}
                                    src={"/spotify_icon.png"}
                                    style={{
                                        maxHeight: "64px",
                                        float: "right",
                                        position: "absolute",
                                        top: "42.5%",
                                        right: "10%",
                                    }}
                                />
                            ) : null}
                        </div>
                        <h1>{workout.name}</h1>
                        <Row>
                            <Col xs={12} md={5}>
                                <p>{workout.description}</p>
                            </Col>
                            <Col xs={12} md={7}>
                                {workout.workoutItems.map((wi, index) => {
                                    const exercise = exercises.find((ex) => ex.id === wi.exerciseId);
                                    if (!exercise) {
                                        return null;
                                    }
                                    let setsAndReps = wi.setsAndReps;
                                    if (setsAndReps.length > 0) {
                                        const parts = setsAndReps.split("-");
                                        let totalSets = 0;
                                        let totalReps = 0;
                                        const setsAndRepsParsed = parts.map((it) => {
                                            return it.split("_");
                                        });
                                        setsAndRepsParsed.forEach((it) => {
                                            if (it.length < 2) {
                                                return;
                                            }
                                            totalSets += parseInt(it[0], 10);
                                            totalReps += parseInt(it[1], 10);
                                        });
                                        setsAndReps = `Total sets: ${totalSets} Total reps: ${totalReps}`;
                                    }
                                    return (
                                        <Row key={index} style={{ margin: 8 }}>
                                            <Col xs={12}>
                                                <Card>
                                                    <Card.Body>
                                                        <Row>
                                                            <Col xs={12} md={4}>
                                                                <Image
                                                                    src={exercise.fields.image ?? image.image}
                                                                    style={{
                                                                        objectFit: "contain",
                                                                        width: "100%",
                                                                        maxHeight: 300,
                                                                    }}
                                                                    fluid
                                                                />
                                                            </Col>
                                                            <Col xs={12} md={8}>
                                                                <Card.Title>{exercise.fields.name}</Card.Title>
                                                                <Card.Text
                                                                    className="exerciseDescription"
                                                                    style={{ maxHeight: "150px", overflowY: "auto" }}>
                                                                    {exercise.fields.description.includes("<")
                                                                        ? renderHTML(exercise.fields.description)
                                                                        : exercise.fields.description}
                                                                </Card.Text>
                                                                <Card.Text>{setsAndReps}</Card.Text>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    );
                                })}
                            </Col>
                        </Row>
                    </Container>
                ) : (
                    <Container
                        style={{
                            textAlign: "center",
                            margin: 16,
                        }}>
                        <MutatingDots
                            
                            color={secondaryColor}
                            height={200}
                            width={100}
                            secondaryColor={accentColor}
                        />
                        <h3>Loading...</h3>
                    </Container>
                )}
                <div style={{ position: "fixed", bottom: 0, left: "5%", right: "5%", zIndex: 1000 }}>
                    <SFAlert
                        show={isMobile}
                        variant={"info"}
                        heading={"Open workout in Strong Foundation mobile app"}
                        body={
                            <Button
                                onClick={this.redirect}
                                variant={"success"}
                                style={{ width: "100%", marginLeft: "auto", marginRight: "auto" }}>
                                Open
                            </Button>
                        }
                    />
                </div>
            </>
        );
    }
    private redirect = async () => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const windowNav = navigator as any;
        if (windowNav.getInstalledRelatedApps) {
            const installedApps: { id: string }[] = await windowNav.getInstalledRelatedApps();
            const nativeApp = installedApps.find((app) => app.id === "com.coding.casa.strongfoundation");
            if (!nativeApp && isAndroid) {
                window.open("https://play.google.com/store/apps/details?id=com.coding.casa.strongfoundation", "_blank");
                return;
            }
        }
        const { id } = this.props.match.params as { id: string };
        window.location.assign(`strongfoundation://shared/workout?id=${id}`);
    };
}

export const WorkoutScreen = withRouter(WorkoutScreenComponent as any);
