import React, { PureComponent } from "react";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import { toast } from "react-hot-toast";
import renderHTML from "react-render-html";
import {MutatingDots} from "react-loader-spinner";
import { accentColor, secondaryColor } from "../../styles/Colors";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { SFAlert } from "../../components/Alert";
import { isMobile, isAndroid } from "react-device-detect";
import Button from "react-bootstrap/Button";
import { Helmet } from "react-helmet";
import { ApplicationClientEndpoint } from "../../utils/Constants";
import { RoutineInflatedModel } from "../../models/RoutineModel";
import { RoutineService } from "../../services/RoutineService";
import { Recipe } from "../../models/Recipe";
import { Workout } from "../../models/Workout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { has } from "lodash";
import { Route } from "../../utils/Routes";

interface IOwnState {
    routine?: RoutineInflatedModel;
}

export class ViewRoutineScreenComponent extends PureComponent<RouteComponentProps, IOwnState> {
    private readonly routineService = new RoutineService();
    public constructor(props: RouteComponentProps) {
        super(props);
        this.state = {
            routine: undefined,
        };
    }

    public componentDidMount() {
        const { id } = this.props.match.params as { id: string };
        this.routineService
            .fetchRoutine(id)
            .then((newsItem) => {
                if (!newsItem) {
                    toast.error("Failed to load routine, please try again later");
                    return;
                }
                this.setState({ routine: newsItem });
            })
            .catch(() => {
                toast.error("Failed to load routine, please try again later");
            });
    }

    public render() {
        return (
            <>
                {this.state.routine ? (
                    <Container
                        style={{
                            marginTop: 16,
                            marginBottom: 16,
                        }}>
                        <Helmet>
                            <meta
                                name="apple-itunes-app"
                                content={`app-id=1564346325, app-argument=post-${this.state.routine._id}`}
                            />
                            <meta property="og:title" content={`Strong Foundation - ${this.state.routine.title}`} />
                            <meta
                                property="og:url"
                                content={`${ApplicationClientEndpoint.substring(
                                    0,
                                    ApplicationClientEndpoint.length - 1,
                                )}${this.props.match.url.replace("routine", "routine")}`}
                            />
                            <meta property="og:image" content={this.state.routine.headerImage} />
                            <meta property="og:description" content={this.state.routine.content} />
                            <title>Strong Foundation - {this.state.routine.title}</title>
                        </Helmet>
                        {this.state.routine.headerImage ? (
                            <div
                                className="module"
                                style={{
                                    backgroundImage: `url(${this.state.routine.headerImage ?? "/sf-feature.png"})`,
                                }}>
                                <Image
                                    className="module-inside"
                                    src={this.state.routine.headerImage}
                                    style={{
                                        objectFit: "contain",
                                        width: "100%",
                                        maxHeight: 300,
                                    }}
                                    fluid
                                />
                            </div>
                        ) : null}
                        <Row>
                            <Col>
                                <h1>{this.state.routine.title}</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col>{renderHTML(this.state.routine.content)}</Col>
                        </Row>
                        {this.state.routine.entries.map((entry, index) => {
                            return <EntryItemRow key={index} entry={entry} />;
                        })}
                    </Container>
                ) : (
                    <Container
                        style={{
                            textAlign: "center",
                            margin: 16,
                        }}>
                        <MutatingDots
                            
                            color={secondaryColor}
                            height={200}
                            width={100}
                            secondaryColor={accentColor}
                        />
                        <h3>Loading...</h3>
                    </Container>
                )}
                <div style={{ position: "fixed", bottom: 0, left: "5%", right: "5%", zIndex: 1000 }}>
                    <SFAlert
                        show={isMobile}
                        variant={"info"}
                        heading={"Open post in Strong Foundation mobile app"}
                        body={
                            <Button
                                onClick={this.redirect}
                                variant={"success"}
                                style={{ width: "100%", marginLeft: "auto", marginRight: "auto" }}>
                                Open
                            </Button>
                        }
                    />
                </div>
            </>
        );
    }

    private redirect = async () => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const windowNav = navigator as any;
        if (windowNav.getInstalledRelatedApps) {
            const installedApps: { id: string }[] = await windowNav.getInstalledRelatedApps();
            const nativeApp = installedApps.find((app) => app.id === "com.coding.casa.strongfoundation");
            if (!nativeApp && isAndroid) {
                window.open("https://play.google.com/store/apps/details?id=com.coding.casa.strongfoundation", "_blank");
                return;
            }
        }
        const { id } = this.props.match.params as { id: string };
        window.location.assign(`strongfoundation://shared/routine?id=${id}`);
    };
}

export const ViewRoutineScreen = withRouter(ViewRoutineScreenComponent as any);

const EntryItemRow = (props: { entry: { label: string; content: (Recipe | Workout)[] } }) => {
    const [isExpanded, setIsExpanded] = React.useState(false);
    const history = useHistory();
    return (
        <Row style={{ marginTop: 36, justifyContent: "center" }}>
            <Col>
                <Row style={{ borderBottom: "1px solid gray", paddingBottom: 8 }}>
                    <Col>
                        <Image
                            style={{ height: 64, width: 64 }}
                            src="https://images.unsplash.com/photo-1602520602053-1d1a6d55a3ea?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
                            roundedCircle
                        />
                    </Col>
                    <Col>
                        <h4>{props.entry.label}</h4>
                    </Col>
                    <Col>
                        <FontAwesomeIcon
                            style={{ color: "white" }}
                            size="2x"
                            icon={isExpanded ? faChevronDown : faChevronUp}
                            aria-label="button"
                            onClick={() => setIsExpanded(!isExpanded)}
                        />
                    </Col>
                </Row>
                {isExpanded
                    ? props.entry.content.map((item, index) => {
                          const image = has(item, "image")
                              ? (item as Recipe).image
                              : "https://images.unsplash.com/photo-1558017487-06bf9f82613a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1540&q=80";
                          return (
                              <Row key={index} style={{ marginTop: 12, marginRight: 8 }}>
                                  <Col>
                                      <Image style={{ height: 64, width: 64 }} src={image} roundedCircle />
                                  </Col>
                                  <Col>
                                      <h6>{has(item, "title") ? (item as Recipe).title : (item as Workout).name}</h6>
                                  </Col>
                                  <Col>
                                      <FontAwesomeIcon
                                          style={{ color: "white" }}
                                          size="lg"
                                          icon={faExternalLinkAlt}
                                          aria-label="link"
                                          onClick={() => {
                                              history.push(
                                                  `${has(item, "title") ? Route.Recipes + "/" : Route.WorkoutBase}${
                                                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                      has(item, "title") ? item.id : (item as any)._id
                                                  }`,
                                              );
                                          }}
                                      />
                                  </Col>
                              </Row>
                          );
                      })
                    : null}
            </Col>
        </Row>
    );
};
