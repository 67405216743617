import React, { PureComponent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { toast } from "react-hot-toast";
import {MutatingDots} from "react-loader-spinner";
import { accentColor, secondaryColor } from "../../styles/Colors";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import { Recipe, RecipeIngredients } from "../../models/Recipe";
import { RecipeService } from "../../services/RecipeService";
import { flatten, sortBy } from "lodash";
import { formatNumber } from "../../utils/Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEgg } from "@fortawesome/free-solid-svg-icons";
import { SFAlert } from "../../components/Alert";
import { isMobile, isAndroid } from "react-device-detect";
import { Helmet } from "react-helmet";
import { ApplicationClientEndpoint } from "../../utils/Constants";

interface IOwnState {
    recipe?: Recipe;
    ingredients?: RecipeIngredients;
}

export class RecipeScreenComponent extends PureComponent<RouteComponentProps, IOwnState> {
    private readonly recipeService = new RecipeService();
    public constructor(props: RouteComponentProps) {
        super(props);
        this.state = {
            recipe: undefined,
            ingredients: undefined,
        };
    }

    public componentDidMount() {
        const { id } = this.props.match.params as { id: string };
        this.recipeService
            .fetchRecipe(id)
            .then((data) => {
                if (!data) {
                    toast.error("Failed to load recipe, please try again later");
                    return;
                }
                const { recipe, ingredients } = data;
                this.setState({ recipe, ingredients });
            })
            .catch(() => {
                toast.error("Failed to load recipe, please try again later");
            });
    }

    public render() {
        return (
            <>
                {this.state.recipe ? (
                    <Container
                        style={{
                            marginTop: 16,
                            marginBottom: 16,
                        }}>
                        <Helmet>
                            <meta
                                name="apple-itunes-app"
                                content={`app-id=1564346325, app-argument=recipe-${this.state.recipe.id}`}
                            />
                            <meta property="og:title" content={`Strong Foundation - ${this.state.recipe.title}`} />
                            <meta
                                property="og:url"
                                content={`${ApplicationClientEndpoint.substring(
                                    0,
                                    ApplicationClientEndpoint.length - 1,
                                )}${this.props.match.url}`}
                            />
                            <meta property="og:image" content={this.state.recipe.image ?? "/sf-feature.png"} />
                            <meta property="og:description" content={this.state.recipe.summary} />
                            <meta property="og:site_name" content="Strong Foundation" />
                            <title>Strong Foundation - {this.state.recipe.title}</title>
                        </Helmet>
                        {this.state.recipe.image ? (
                            <div
                                className="module"
                                style={{
                                    backgroundImage: `url(${this.state.recipe.image ?? "/sf-feature.png"})`,
                                }}>
                                <Image
                                    className="module-inside"
                                    src={this.state.recipe.image}
                                    style={{
                                        objectFit: "contain",
                                        width: "100%",
                                        maxHeight: 300,
                                    }}
                                    fluid
                                />
                            </div>
                        ) : null}
                        <h1>{this.state.recipe.title}</h1>
                        <p>
                            <em>Ready in: {this.state.recipe.readyInMinutes} minutes</em>&nbsp;&nbsp;&nbsp;&nbsp;
                            <em>Servings: {this.state.recipe.servings}</em>
                        </p>

                        <div dangerouslySetInnerHTML={{ __html: this.state.recipe?.summary ?? "" }} />
                        {this.renderIngredients()}
                        {this.renderSteps()}
                    </Container>
                ) : (
                    <Container
                        style={{
                            textAlign: "center",
                            margin: 16,
                        }}>
                        <MutatingDots
                            
                            color={secondaryColor}
                            height={200}
                            width={100}
                            secondaryColor={accentColor}
                        />
                        <h3>Loading...</h3>
                    </Container>
                )}
                <div style={{ position: "fixed", bottom: 0, left: "5%", right: "5%", zIndex: 1000 }}>
                    <SFAlert
                        show={isMobile}
                        variant={"info"}
                        heading={"Open recipe in Strong Foundation mobile app"}
                        body={
                            <Button
                                onClick={this.redirect}
                                variant={"success"}
                                style={{ width: "100%", marginLeft: "auto", marginRight: "auto" }}>
                                Open
                            </Button>
                        }
                    />
                </div>
            </>
        );
    }

    private redirect = async () => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const windowNav = navigator as any;
        if (windowNav.getInstalledRelatedApps) {
            const installedApps: { id: string }[] = await windowNav.getInstalledRelatedApps();
            const nativeApp = installedApps.find((app) => app.id === "com.coding.casa.strongfoundation");
            if (!nativeApp && isAndroid) {
                window.open("https://play.google.com/store/apps/details?id=com.coding.casa.strongfoundation", "_blank");
                return;
            }
        }
        const { id } = this.props.match.params as { id: string };
        window.location.assign(`strongfoundation://shared/recipes?id=${id}`);
    };

    private renderIngredients = () => {
        if (!this.state.ingredients || !this.state.ingredients.ingredients) {
            return null;
        }
        return (
            <>
                <h3>Ingredients</h3>
                <hr />
                <ul className="fa-ul">
                    {this.state.ingredients.ingredients.map((item, index) => {
                        let value = formatNumber(item.amount.us.value, 2);
                        while (value[value.length - 1] === "0" || value[value.length - 1] === ".") {
                            const didRemoveDecimal = value[value.length - 1] === ".";
                            value = value.substring(0, value.length - 1);
                            if (didRemoveDecimal) {
                                break;
                            }
                        }
                        return (
                            <li key={index}>
                                <span className="fa-li" style={{ color: secondaryColor }}>
                                    <FontAwesomeIcon icon={faEgg} />
                                </span>
                                {`${value} ${!item.amount.us.unit ? "" : item.amount.us.unit} of ${item.name}`}
                            </li>
                        );
                    })}
                </ul>
            </>
        );
    };
    private renderSteps = () => {
        if (!this.state.recipe?.analyzedInstructions) {
            return null;
        }
        const steps = flatten(this.state.recipe.analyzedInstructions.map((ins) => ins.steps));
        const sortedSteps = sortBy(steps, (step) => step.number);
        return (
            <>
                <h3>Steps</h3>
                <hr />
                <ol>
                    {sortedSteps.map((step, index) => (
                        <li key={index}>{step.step}</li>
                    ))}
                </ol>
            </>
        );
    };
}

export const RecipeScreen = withRouter(RecipeScreenComponent as any);
