import React, { PureComponent, useState } from "react";
import Cookies from "js-cookie";
import { SpotifyAuth, Scopes } from "react-spotify-auth";
import "react-spotify-auth/dist/index.css";
import { SpotifyClientId, SpotifyRedirectUri } from "../../utils/Constants";
import { SpotifyService } from "../../services/SpotifyService";
import { SpotifyPlaylist } from "../../models/SpotifyPagingObject";
import { toast } from "react-hot-toast";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { secondaryColor, whiteColor } from "../../styles/Colors";
import {isEqual} from "lodash";

interface IOwnProps {
    onPlaylistSelected: (playlist: string) => void;
    accessToken: string;
    onUnauthorized: () => void;
}

export const SpotifyControl = React.memo((props: { getPlaylist?: (cb: () => string) => void }) => {
    const [token, setToken] = useState<string | undefined>(Cookies.get("spotifyAuthToken"));
    const [spotifyPlaylist, setPlaylist] = useState("");
    const getPlaylist = () => spotifyPlaylist;
    if (props.getPlaylist) {
        props.getPlaylist(getPlaylist);
    }
    return token ? (
        <SpotifyPlaylists
            accessToken={token}
            onPlaylistSelected={setPlaylist}
            onUnauthorized={() => {
                Cookies.set("spotifyAuthToken", "");
                setToken(undefined);
            }}
        />
    ) : (
        <SpotifyAuth
            redirectUri={SpotifyRedirectUri}
            clientID={SpotifyClientId}
            scopes={[Scopes.userReadPrivate, "user-read-email"]}
            onAccessToken={(accessToken: string) => {
                Cookies.set("spotifyAuthToken", accessToken);
                setToken(accessToken);
            }}
        />
    );
}, isEqual);

interface IOwnState {
    selectedPlaylist: string;
    playlists: SpotifyPlaylist[];
}

export class SpotifyPlaylists extends PureComponent<IOwnProps, IOwnState> {
    private readonly spotifyService: SpotifyService;
    public constructor(props: IOwnProps) {
        super(props);
        this.state = {
            selectedPlaylist: "",
            playlists: [],
        };
        this.spotifyService = new SpotifyService(props.accessToken);
    }

    public componentDidMount() {
        this.spotifyService
            .fetchUserPlaylists()
            .then((playlists) => this.setState({ playlists }))
            .catch((ex) => {
                if (ex && ex.response && ex.response.status === 401) {
                    this.props.onUnauthorized();
                }
                toast.error("Failed to find Spotify playlist(s)");
            });
    }
    public render() {
        return (
            <Container id="spotify_playlists" fluid style={{ maxHeight: 250, overflow: "auto" }}>
                {this.state.playlists.length > 0 ? this.state.playlists.map(this.renderItem) : null}
            </Container>
        );
    }

    private renderItem = (item: SpotifyPlaylist, index: number) => {
        const { images, id, name } = item;
        return (
            <Row key={index} style={{ marginBottom: 4, borderBottom: "1px solid white", padding: 4 }}>
                <Col xs={3} md={4}>
                    <Image
                        roundedCircle
                        src={images && images.length > 0 ? images[0].url : "/sf-feature.png"}
                        style={{ maxHeight: "96px" }}
                    />
                </Col>
                <Col>
                    <h5 style={{ textAlign: "center", position: "absolute", top: "25%" }}>
                        {name}&nbsp;&nbsp;&nbsp;&nbsp;
                        <FontAwesomeIcon
                            id="icon"
                            icon={faCheckCircle}
                            color={this.state.selectedPlaylist === id ? secondaryColor : whiteColor}
                            onClick={() => {
                                this.setState(
                                    { selectedPlaylist: this.state.selectedPlaylist === id ? "" : id },
                                    () => {
                                        if (this.state.selectedPlaylist.length === 0) {
                                            this.props.onPlaylistSelected("");
                                            return;
                                        }
                                        this.props.onPlaylistSelected(`${item.uri}|${item.id}||${item.name}`);
                                    },
                                );
                            }}
                        />
                    </h5>
                </Col>
            </Row>
        );
    };
}
