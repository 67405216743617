import React, { PureComponent, SyntheticEvent } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import { RouteComponentProps, withRouter } from "react-router-dom";
import AvailableExercises from "./AvailableExercises";
import WorkoutForm from "./WorkoutForm";
import { toast } from "react-hot-toast";
import WorkoutExercise from "./WorkoutExercises";
import { filter, has, isEmpty, unset } from "lodash";
import {DateTime} from "luxon";
import { WorkoutItem as WI } from "../../models/Workout";
import { hashCode } from "../../utils/Utilities";
import { Exercise } from "../../models/Exercise";
import { CommunityService } from "../../services/CommunityService";
import { ApplicationClientEndpoint } from "../../utils/Constants";
import { Helmet } from "react-helmet";

interface IState {
    form: JSX.Element | null;
    selectedExercises: Exercise[];
    workoutId?: string | null;
}

class WorkoutBuilder extends PureComponent<RouteComponentProps, IState> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private exerciseItemRefs: { [key: string]: any } = {};
    private readonly communityService = new CommunityService();
    private getFormData: (() => {
        description: string;
        name: string;
        spotifyPlaylistUrl: string;
        isYogaSequence: boolean;
        isTabataFlow: boolean;
        yogaTTSPace: number;
        phoneNumbers?: string;
        isPublished: boolean;
    }) | undefined = undefined;

    public constructor(props: RouteComponentProps) {
        super(props);
        this.state = {
            form: <WorkoutForm getFormSubmissionData={this.setGetFormData} item={undefined} />,
            selectedExercises: [],
            workoutId: undefined,
        };
    }
    public render() {
        return (
            <Container fluid style={{ marginTop: 8, marginBottom: 16 }}>
                <Helmet>
                    <meta property="og:title" content="Strong Foundation - Workout Builder" />
                    <meta
                        property="og:url"
                        content={`${ApplicationClientEndpoint.substring(0, ApplicationClientEndpoint.length - 1)}${
                            this.props.match.url
                        }`}
                    />
                    <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
                    <meta property="og:description" content="Build a workout on Strong Foundation" />
                    <meta property="og:site_name" content="Strong Foundation" />
                    <title>Strong Foundation - Workout Builder</title>
                </Helmet>
                <h1>Workout Builder</h1>
                {this.state.workoutId ? (
                    <p>
                        Use the link below to share this workout with friends and family
                        <br />
                        <br />
                        <a
                            href={`${ApplicationClientEndpoint}workout/${this.state.workoutId}`}
                            target="_blank"
                            rel="noopener noreferrer">{`${ApplicationClientEndpoint}workout/${this.state.workoutId}`}</a>
                    </p>
                ) : null}
                <Row>
                    <Col md={6}>
                        <Form onSubmit={this.submittedWorkout}>
                            <Row>
                                <Col md={12}>{this.state.form}</Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <ListGroup style={{ marginTop: 16 }}>
                                        {this.state.selectedExercises
                                            ? this.state.selectedExercises.map((ex) => {
                                                return (
                                                    <WorkoutExercise
                                                        key={ex.id}
                                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                        ref={(ref: any) => {
                                                            this.exerciseItemRefs[ex.id] = ref;
                                                        }}
                                                        exercise={ex}
                                                        onRemoved={(toRemove) => {
                                                            if (has(this.exerciseItemRefs, ex.id)) {
                                                                unset(this.exerciseItemRefs, ex.id);
                                                            }
                                                            const exercises = filter(
                                                                this.state.selectedExercises,
                                                                (e) => e.id !== toRemove.id,
                                                            );
                                                            this.setState({ selectedExercises: exercises });
                                                        }}
                                                    />
                                                );
                                            })
                                            : null}
                                    </ListGroup>
                                </Col>
                            </Row>
                            <Row style={{marginTop: 24}}>
                                <Col md={12}>
                                    <Button variant="primary" type="submit">
                                        Submit
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col md={6}>
                        <AvailableExercises onExercisesSelected={this.onExercisesSelected} />
                    </Col>
                </Row>
            </Container>
        );
    }

    private setGetFormData = (cb: () => {
        description: string;
        name: string;
        spotifyPlaylistUrl: string;
        isYogaSequence: boolean;
        isTabataFlow: boolean;
        yogaTTSPace: number;
        phoneNumbers?: string;
        isPublished: boolean;
    }) => {
        this.getFormData = cb;
    }

    private onExercisesSelected = (selected: Exercise[]) => {
        const exercises = this.state.selectedExercises;
        const selectedExercises = exercises.concat(selected);
        this.setState({ selectedExercises });
    };

    private submittedWorkout = async (event: SyntheticEvent) => {
        event.preventDefault();
        const data = this.getFormData ? this.getFormData() : {} as any;
        const date = DateTime.utc().toMillis();
        const exercises = this.state.selectedExercises;
        const workoutUuid = `webClientCreated_${hashCode(date.toString())}_${date}`;
        if (!exercises || isEmpty(exercises)) {
            toast.error("Please select some exercises");
            return;
        }
        if (!data.name) {
            toast.error("Please provide a title for your workout");
            return;
        }
        if (!data.description) {
            toast.error("Please provide a description for your workout");
            return;
        }
        const workout = {
            dateCreated: date,
            dateModified: date,
            description: data.description,
            spotifyPlaylistUrl: data.spotifyPlaylistUrl,
            name: data.name,
            workoutInProgress: false,
            yogaTTSPace: data.yogaTTSPace,
            isTabataFlow: data.isTabataFlow,
            isYogaSequence: data.isYogaSequence,
            id: Math.abs(hashCode(workoutUuid)),
            isShared: true,
            roundsToComplete: 5,
            startedAt: 0,
            lastDuration: 0,
            uuid: workoutUuid,
            workoutItems: exercises.map((ex, index) => {
                return {
                    category: ex.fields.categoryName,
                    dateCreated: date,
                    dateModified: date,
                    description: ex.fields.description,
                    exerciseId: ex.id,
                    name: ex.fields.name,
                    parentWorkoutId: -1,
                    position: index,
                    setsAndReps: this.exerciseItemRefs[ex.id].getSetsAndReps(),
                    uniqueId: hashCode(workoutUuid) + "",
                } as WI;
            }),
        };
        try {
            const id = await this.communityService.buildWorkout(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                workout as any,
                data.phoneNumbers?.split(","),
            );
            if (!id) {
                throw new Error();
            }
            this.exerciseItemRefs = {};
            this.setState({
                selectedExercises: [],
                form: (
                    <WorkoutForm
                        getFormSubmissionData={this.setGetFormData}
                        isReadOnly={false}
                        item={undefined}
                        isPublished={false}
                    />
                ),
                workoutId: id,
            });
            toast.success("Saved workout");
        } catch (ex) {
            toast.error("Failed to save");
        }
    };
}

export const WorkoutBuilderScreen = withRouter(WorkoutBuilder as any);
