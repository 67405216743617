export const getLocaleCode = (options = {}) => {
    const defaultOptions = {
        languageCodeOnly: false,
    };

    const opt = {
        ...defaultOptions,
        ...options,
    };

    const browserLocales = navigator.languages === undefined ? [navigator.language] : navigator.languages;

    if (!browserLocales) {
        console.log("no browser locale")
        return "en";
    }

    const locales = browserLocales.map((locale) => {
        const trimmedLocale = locale.trim();

        return opt.languageCodeOnly ? trimmedLocale.split(/-|_/)[0] : trimmedLocale;
    });

    const locale = locales[0];
    if (!locale) {
        return "en";
    }
    return locale.includes("-") ? locale.substr(0, locale.indexOf("-")) : locale;
};

export const formatNumber = (input: number, digits: number): string => input.toFixed(digits).toString();

export const hashCode = (str: string) => {
    let hash = 0,
        i,
        chr;
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
};

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
