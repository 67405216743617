import React, { PureComponent } from "react";
import Container from "react-bootstrap/Container";
import InputGroup from "react-bootstrap/InputGroup";
import ListGroup from "react-bootstrap/ListGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import { has, unset, filter, values, extend } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import SelectableExercise from "./SelectableExercise";
import { ExerciseService } from "../../services/ExerciseService";
import { Exercise } from "../../models/Exercise";

interface IProps {
    onExercisesSelected: (exercises: Exercise[]) => void;
}

interface IState {
    exercises: Exercise[];
    searchedExercises: Exercise[];
    selectedExercises: { [key: string]: Exercise };
}

export default class AvailableExercises extends PureComponent<IProps, IState> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private search: any = null;
    private readonly exerciseService = new ExerciseService();
    public constructor(props: IProps) {
        super(props);
        this.state = {
            exercises: [],
            searchedExercises: [],
            selectedExercises: {}
        };
    }

    public componentDidMount() {
        this.exerciseService
            .fetchExercises()
            .then((exercises) => {
                this.setState({ exercises, searchedExercises: exercises });
            })
            .catch(() => {
                // no op
            });
    }

    public render() {
        return (
            <Container>
                <Row>
                    <InputGroup className="mb-3">
                        <InputGroup.Text>
                            <FontAwesomeIcon icon={faSearch} />
                        </InputGroup.Text>
                        <FormControl
                            placeholder="Search"
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            ref={(ref: any) => {
                                this.search = ref;
                            }}
                            onChange={this.searchForExercises}
                        />
                    </InputGroup>
                </Row>
                <Row>
                    <ListGroup id="availableExercises" style={{ maxHeight: 400, overflowY: "scroll", width: "100%" }}>
                        {this.state.searchedExercises.map((exercise) => {
                            return (
                                <SelectableExercise
                                    key={exercise.id}
                                    exercise={exercise}
                                    isSelected={has(this.state.selectedExercises, exercise.id)}
                                    onSelected={this.onExerciseSelected}
                                />
                            );
                        })}
                    </ListGroup>
                </Row>
                <Button
                    style={{ marginTop: 16 }}
                    onClick={this.addSelectedExercises}>
                    Add selected exercises
                </Button>
            </Container>
        );
    }

    private addSelectedExercises = () => {
        this.props.onExercisesSelected(values(this.state.selectedExercises));
        this.setState({ searchedExercises: [], selectedExercises: {} }, () => {
            this.setState({ searchedExercises: this.state.exercises });
        });
    }

    private onExerciseSelected = (exercise: Exercise) => {
        const selected = extend({}, this.state.selectedExercises, {});
        if (has(selected, exercise.id)) {
            unset(selected, exercise.id);
        } else {
            selected[exercise.id] = exercise;
        }
        this.setState({selectedExercises: selected})
    };

    private searchForExercises = () => {
        const searchText = this.search.value;
        if (!searchText) {
            this.setState({ searchedExercises: this.state.exercises });
            return;
        }
        const exercises = filter(this.state.exercises, (exercise) => {
            return exercise.fields.name.toLowerCase().includes(searchText.toLowerCase());
        });
        this.setState({ searchedExercises: exercises });
    };
}
