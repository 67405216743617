import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Route } from "../utils/Routes";

const footerLinks = [
    {
        link: "https://codinghouse.dev",
        text: "Contact",
    },
    {
        link: "https://strongfoundation.dev/privacypolicy",
        text: "Privacy policy",
    },
    {
        link: "https://projects.codinghouse.dev/project/yorel56-strong-foundation/kanban",
        text: "Backlog",
    },
];
const headerLinks = [
    {
        link: Route.Community,
        text: "Community",
    },
    {
        link: Route.WorkoutBuilder,
        text: "Workout Builder",
    },
];

export const MainNavbar = () => {
    return (
        <Navbar bg={"light"} expand="lg">
            <Navbar.Brand href="/">
                <Image src="/favicon-32x32.png" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    {headerLinks.map((link, index) => (
                        <Nav.Item key={index}>
                            <Nav.Link href={link.link}>{link.text}</Nav.Link>
                        </Nav.Item>
                    ))}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export const Footer = () => {
    return (
        <footer className="footer mt-auto py-3 bg-light">
            <Container fluid>
                <Row>
                    {footerLinks.map((link, index) => (
                        <Col key={index} md={4} sm={4} xs={4} style={{ justifyContent: "center" }}>
                            <a href={link.link} style={{ textAlign: "center" }}>
                                {link.text}
                            </a>
                        </Col>
                    ))}
                </Row>
            </Container>
        </footer>
    );
};
