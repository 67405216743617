export class SfStringBuilder {
    private values: string[];

    constructor(initialValue: string = '') {
        this.values = [initialValue];
    }

    public append(value: string): void {
        this.values.push(value);
    }

    public appendLine(value: string = ''): void {
        this.values.push(value + '\n');
    }

    public toString(): string {
        return this.values.join('');
    }

    public clear(): void {
        this.values = [];
    }
}