import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Helmet } from "react-helmet";
import { ApplicationClientEndpoint } from "../../utils/Constants";
import { useRouteMatch } from "react-router-dom";
export const HomeScreen = () => {
    const match = useRouteMatch();
    return (
        <>
            <Helmet>
                <meta name="apple-itunes-app" content={`app-id=1564346325`} />
                <meta property="og:title" content="Strong Foundation" />
                <meta
                    property="og:url"
                    content={`${ApplicationClientEndpoint.substring(0, ApplicationClientEndpoint.length - 1)}${
                        match.url
                    }`}
                />
                <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
                <meta property="og:description" content="Strong Foundation Home" />
                <title>Strong Foundation - Your Ultimate Health Companion</title>
            </Helmet>
            <Row style={{ marginTop: 24, marginBottom: 24, justifyContent: "center" }}>
                <Card style={{ maxWidth: "75%" }}>
                    <Card.Img variant="top" src="/sf-feature.png" />
                    <Card.Body>
                    <Card.Title>Welcome to Strong Foundation - the ultimate companion on your
                        journey to a healthier, happier you!</Card.Title>
                    <Row>
                        <Col>
                            <Card.Text>
                                <p>Picture this: a sleek, modern app that not only helps you crush your fitness
                                    goals but also empowers you to take charge of every aspect of your well-being -
                                    from physical strength to mental clarity and everything in between. Say goodbye
                                    to the days of juggling multiple apps for different health needs. Strong
                                    Foundation is your all-in-one solution, designed to streamline your wellness
                                    journey with style and simplicity.</p>
                                <p>What sets us apart? It's simple - we put YOU in the driver's seat. With
                                    customizable workouts ranging from heart-pumping HIIT sessions to serene yoga
                                    flows, you'll have the freedom to sculpt the perfect fitness routine tailored to
                                    your unique preferences and goals. But that's just the beginning. With our
                                    intuitive diet planner, you can bid farewell to bland meal prep and hello to
                                    delicious, nutritious recipes personalized to your dietary preferences and
                                    weight management goals.</p>
                                <p>But here's the best part: your privacy is our top priority. Unlike other apps
                                    that trade your data like currency, Strong Foundation operates on a strict
                                    opt-in policy, ensuring that your personal information remains confidential and
                                    secure. Your data stays right where it belongs - on your device - never to be
                                    shared or sold to third parties.</p>
                                <p>Oh, and did we mention community? Get ready to connect, inspire, and thrive
                                    alongside like-minded individuals in our vibrant online community. Share your
                                    favorite recipes, swap workout tips, and even create custom routines with
                                    friends for an extra dose of motivation.</p>
                                <p>So why wait? Take the reins of your health journey today and let Strong
                                    Foundation be your guiding light. With us by your side, the sky's the limit -
                                    let's make every step count!</p>
                            </Card.Text>
                        </Col>
                    </Row>
                        <Row>
                            <Col sm={6}>
                                <Card.Img
                                    variant="bottom"
                                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                                    // style={{ maxWidth: "50%" }}
                                    onClick={() => {
                                        window.location.href =
                                            "https://play.google.com/store/apps/details?id=com.coding.casa.strongfoundation";
                                    }}
                                />
                            </Col>
                            <Col sm={6}>
                                <Card.Img
                                    variant="bottom"
                                    src="/ios_badge.svg"
                                    // style={{ maxWidth: "50%" }}
                                    onClick={() => {
                                        window.location.href = "https://apps.apple.com/app/id1564346325";
                                    }}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Row>
        </>
    );
};
