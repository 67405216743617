import axios from "axios";
import { AuthenticationHelper } from "../utils/AuthenticationHelper";
import { ApplicationApiEndpoint } from "../utils/Constants";

export class StrongFoundationService {
    private readonly authHelper = new AuthenticationHelper();
    private getToken = () => this.authHelper.fetchTokenAsync();
    protected get = async <T>(endpoint: string, params: unknown = undefined): Promise<T | undefined> => {
        try {
            const response = await axios.get<T>(`${ApplicationApiEndpoint}${endpoint}`, {
                headers: {
                    authorization: `Bearer ${await this.getToken()}`,
                },
                params,
            });
            return response.data;
        } catch (ex) {
            return undefined;
        }
    };
    protected delete = async (endpoint: string, params: unknown = undefined): Promise<boolean> => {
        try {
            await axios.delete(`${ApplicationApiEndpoint}${endpoint}`, {
                headers: {
                    authorization: `Bearer ${await this.getToken()}`,
                },
                params,
            });
            return true;
        } catch (ex) {
            return false;
        }
    };
    protected post = async <T>(endpoint: string, body: unknown = undefined): Promise<T | undefined> => {
        try {
            const response = await axios.post<T>(`${ApplicationApiEndpoint}${endpoint}`, body, {
                headers: {
                    authorization: `Bearer ${await this.getToken()}`,
                },
            });
            return response.data;
        } catch (ex) {
            return undefined;
        }
    };
    protected put = async <T>(endpoint: string, body: unknown = undefined): Promise<T | undefined> => {
        try {
            const response = await axios.put<T>(`${ApplicationApiEndpoint}${endpoint}`, body, {
                headers: {
                    authorization: `Bearer ${await this.getToken()}`,
                },
            });
            return response.data;
        } catch (ex) {
            return undefined;
        }
    };
}
