import React from "react";
import Container from "react-bootstrap/Container";
import { Route, Switch } from "react-router-dom";
import { Routes } from "./utils/Routes";
import { HomeScreen } from "./screens/home/HomeScreen";
import { Footer, MainNavbar } from "./components/MainNavbar";
import { Toaster } from "react-hot-toast";
import './App.css';

function App() {
    return (
        <Container className="App" fluid>
            <MainNavbar />
            <Container fluid>
                <Toaster />
                <Switch>
                    {Routes.map((route, index) => (
                        <Route key={index} path={route.route} component={route.component} exact={route.exact} />
                    ))}
                    <Route component={HomeScreen} />
                </Switch>
            </Container>
            <Footer />
        </Container>
    );
}

export default App;
