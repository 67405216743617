export const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

export const ApplicationApiEndpoint = isDev
    ? "http://192.168.87.24:5001/api/v1.0/"
    : "https://strongfoundation.dev/api/v1.0/";
export const ApplicationClientEndpoint = isDev ? "http://192.168.87.24:50001/" : "https://strongfoundation.dev/";
export const SpotifyRedirectUri = isDev
    ? "http://localhost:50001/workoutbuilder"
    : "https://strongfoundation.dev/workoutbuilder";
export const SpotifyClientId = "99cd21c4e460477596ecbad9976f2680";
export const AzureClientId = isDev ? "da6de2cd-c23e-4384-98e3-7f9859f0891f" : "96b35b0e-d820-43b2-9a2c-d762492b9072";
export const AzureTenantId = isDev ? "29bb0275-b017-4168-9005-3ed2ed0bccbf" : "29bb0275-b017-4168-9005-3ed2ed0bccbf";